import Swiper from "swiper";

export default (numberOfSlides) => ({
    swiper: null,

    get isMobile() {
        return !this.$store.breakpoint.sm;
    },

    init() {
        if (numberOfSlides < 2) {
            return;
        }

        this.$watch("isMobile", () => {
            this.initSwiper();
        });

        this.initSwiper();
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    initSwiper() {
        if (this.swiper || !this.isMobile) {
            return;
        }

        this.swiper = new Swiper(this.$refs.newsSwiper, {
            slidesOffsetBefore: 10,
            slidesPerView: "auto",

            breakpoints: {
                390: { slidesOffsetBefore: 30 },
                768: { slidesOffsetBefore: 0 },
            },
        });
    },
});
