const xsMediaQuery = window.matchMedia("(min-width: 390px)");
const smMediaQuery = window.matchMedia("(min-width: 640px)");
const mdMediaQuery = window.matchMedia("(min-width: 768px)");

export default {
    xs: xsMediaQuery.matches,
    sm: smMediaQuery.matches,
    md: mdMediaQuery.matches,

    init() {
        xsMediaQuery.addEventListener("change", (e) => (this.xs = e.matches));

        smMediaQuery.addEventListener("change", (e) => (this.sm = e.matches));

        mdMediaQuery.addEventListener("change", (e) => (this.md = e.matches));
    },
};
