import "./bootstrap.js";
import "./swup.js";

import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";

import breakpoint from "./stores/breakpoint.js";
import header from "./components/header.js";
import surprise from "./components/surprise.js";
import newsSlider from "./components/newsSlider.js";
import homeSlider from "./components/homeSlider.js";
import videoPlayer from "./components/videoPlayer.js";
import projectGrid from "./components/projectGrid.js";
import projectGallery from "./components/projectGallery.js";

window.Alpine = Alpine;

Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(focus);

document.addEventListener("alpine:init", () => {
    Alpine.data("projectGallery", projectGallery);
    Alpine.data("projectGrid", projectGrid);
    Alpine.data("videoPlayer", videoPlayer);
    Alpine.data("homeSlider", homeSlider);
    Alpine.data("newsSlider", newsSlider);
    Alpine.data("surprise", surprise);
    Alpine.data("header", header);
    Alpine.store("breakpoint", breakpoint);
});

Alpine.start();
