import Swup from "swup";
import SwupScriptsPlugin from "@swup/scripts-plugin";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupFragmentPlugin from "@swup/fragment-plugin";

// Change to this once swup fragment updates path to regexp
// export const projectPattern = "/projekte{/:slug}";
export const projectPattern = "/projekte/:slug?";

export const swup = new Swup({
    containers: ["#swup"],
    plugins: [
        new SwupFragmentPlugin({
            rules: [
                {
                    from: projectPattern,
                    to: projectPattern,
                    containers: ["#header"],
                },
            ],
        }),
        // Email obfuscator
        new SwupScriptsPlugin({
            head: false,
            body: true,
        }),
        new SwupProgressPlugin({
            delay: 500,
        }),
        new SwupPreloadPlugin(),
    ],
});
