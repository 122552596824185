export default () => ({
    timer: false,
    confetto: null,

    ins() {
        this.timer = setTimeout(() => this.shoot(), 2000);
    },

    outs() {
        clearTimeout(this.timer);
    },

    async shoot() {
        if (this.confetto === null) {
            await this.loadConfetto();
        }

        const randomColorFromArray = (array) =>
            array[Math.floor(Math.random() * array.length)];

        this.confetto.addConfetti({
            confettiColors: [
                "#F9F9F9",
                "#F3F3F3",
                "#DFDFDF",
                "#B5B5B5",
                "#4B4B4B",
                randomColorFromArray([
                    "#FF6EC7",
                    "#00F0FF",
                    "#39FF14",
                    "#FFFF00",
                    "#FFA500",
                    "#BF00FF",
                ]),
            ],
        });

        if (window.plausible) {
            window.plausible("Confetti");
        }
    },

    async loadConfetto() {
        const { default: JSConfetti } = await import("js-confetti");
        this.confetto = new JSConfetti();
    },
});
